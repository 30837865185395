import React, { useState, useEffect, useRef, Fragment } from 'react';

import QrReader from 'react-qr-reader'


import { FetchWrapper } from '../helpers/FetchWrapper'
import { Button } from 'reactstrap';
import { Card, CardTitle, CardText, Spinner, Col, Row, Alert, UncontrolledAlert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faStopwatch, faBan, faCamera } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment'
import { Timer } from './Timer';
export const Stamp = (props) => {

    const [geoEnabled, setGeoEnabled] = useState(false);
    const [stamps, setStamps] = useState([]);
    const [second, setSecond] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [serverTime, setServerTime] = useState(Date.now);

    const [stampLoading, setStampLoading] = useState(false);
    const [scannedData, setScannedData] = useState("");

    const [userCamera, setUseCamera] = useState(false);
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setGeoEnabled(true)
        }, function (error) {
            if (error.code == error.PERMISSION_DENIED) {
                setGeoEnabled(false);
            }
        }, { enableHighAccuracy: true });

        GetStamps();

    }, []);


    useEffect(() => {

        if (scannedData != "") {

            FetchWrapper.get("/api/contract/" + scannedData).then(data => {
                if (data.json != null) {
                    Stamp();
                    setError("");
                }
                
            }).catch(error => {
                if (error.status == 404) {
                    setError(`Kontrakt  ${scannedData} finns ej`);
                }
            });

        }
    }, [scannedData]);


    const GetStamps = async () => {
        setLoading(true);

        await FetchWrapper.get("/api/stamp").then(data => {
            setStamps(data.json);
            setServerTime(data.headers.get("date"));

        }).catch(error => {
            console.log(error);
        });
        setLoading(false);


    }

    const GetPosition = async () => {


        const pos = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, { enableHighAccuracy: true });
        });

        return pos.coords;

    }

    const StampOut = async (id) => {

        setStampLoading(true);
        await FetchWrapper.delete("/api/stamp/" + id).then(data => {
            setSecond(0);
            GetStamps();
            

        }).catch(error => {
            console.log(error);
        });

        setStampLoading(false);

    }

    const Stamp = async () => {
        setStampLoading(true);

        var pos = await GetPosition();

        await FetchWrapper.post("/api/stamp/", { longitude: pos.longitude, latitude: pos.latitude, contractid: scannedData }).then(data => {
            GetStamps();
            setScannedData("");

        }).catch(error => {
            console.log(error);
        });

        setStampLoading(false);


    }

    const handleScan = (data) => {
        if (data) {
            setScannedData(data);
            setUseCamera(false);
        }
    }
    const handleError = (err) => {
        console.error(err);
    }

    const ActivatedCamera = () => {
        return (
            <div>
                <QrReader
                    delay={300}
                    style={{ height: "100%" }}
                    facingMode={"environment"}
                    onError={handleError}
                    onScan={handleScan}
                       />
                <Row>
                    <Col>
                        <Button color="secondary" className="btn-lg p-3 w-100 mt-2 mb-2" onClick={() => setUseCamera(!userCamera)}>Avbryt<FontAwesomeIcon icon={faCamera} className="ml-2" /></Button>

                    </Col>
                </Row>
            </div>
        )
    }

    const NoStamps = () => {
        return (
            <div>
                {!userCamera ? <div>
                    {!geoEnabled && <Alert color="primary" className="col col-md-12 mb-4" fade={false} ><p className="lead m-1">Observera att platstjänster måste aktiveras för att instämpling skall fungera korrekt.</p></Alert>}

                    <Row>
                        <Col>
                            <Button onClick={() => Stamp()} disabled={stampLoading} color="success" className="btn-lg p-3 w-100 mt-2" size="lg" >Stämpla in <FontAwesomeIcon icon={faStopwatch} className="ml-2" /></Button>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" className="btn-lg p-3 w-100 mt-2 mb-2" onClick={() => setUseCamera(!userCamera)}>Stämpla in med QR kod <FontAwesomeIcon icon={faCamera} className="ml-2" /></Button>

                        </Col>
                    </Row>

                </div> : <ActivatedCamera /> }
                
            </div>

        )
    }

    const Stamps = () => {
        return (
            <div>
                {
                    stamps.map((item, index) => (
                        <Row key={index} className="">
                            <Col md="" className="">
                                <Card body>
                                    <CardTitle tag="h4">{item.contract}</CardTitle>
                                    <CardText><b>INSTÄMPLING:</b> {moment(item.timeStamp).format("YYYY-MM-DD HH:mm:ss")}
                                        <br /> <Timer timeStamp={item.timeStamp} serverTime={serverTime} seconds={second} />
                                    </CardText>
                                    <Button disabled={stampLoading} color="danger" className="btn-lg p-2" onClick={() => StampOut(item.lopn)}>Stämpla ut  <FontAwesomeIcon icon={faBan} className="ml-2" /></Button>
                                </Card>
                            </Col>
                        </Row>
                    ))
                }
            </div>
        )
    }
    const Loaded = () => {
        return (
            <div>
                {error != "" ? <UncontrolledAlert color="danger">
                    {error}
                </UncontrolledAlert> : ""}
                
                {stamps.length == 0 ? <NoStamps /> : <Stamps />}

            </div>
        );
    }

    if (loading) {
        return (<Row>
            <Col className="text-center pt-10"><Spinner color="primary" /></Col>
        </Row>)

    }
    else {

        return <Loaded />
    }


}



