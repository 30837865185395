import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
//import logo from '../img/Pajp_prisma.png';
import {
    Container, Col, Form,
    FormGroup, Label, Input,
    Button,
} from 'reactstrap';

export const Login = (props) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();

    const [errMess, setErrorMess] = useState(false);
    const showHideError = () => setErrorMess(errMess => !errMess);

    useEffect(() => {
        localStorage.clear();
    });

    const validateForm = () => {
        return username.length > 0 && password.length > 0;
    }
    //const error = "test";
    const handleSubmit = (event) => {
        event.preventDefault();

        fetch("/api/token", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: username, password: password })
        }).then((response) => {
            if (response.ok) {
                setErrorMess(errMess => false);
                response.json().then(data => {
                    localStorage.setItem("token", data.token);
                    localStorage.setItem("user", JSON.stringify(data.user));
                    history.push("/stamp");
                })
            }
            else {
                setErrorMess(errMess => true);
                console.log(response);
            }
        }).catch((error) => {
            console.log('error: ' + error);

        });


    }



    return (
        <div className="wrapper container h-100">
            <div className="Login row h-100 justify-content-center align-items-center">
                <Form className="form m-0 col col-md-12 col-lg-8" onSubmit={handleSubmit}>
                    <Col className="text-center">
                        <img className="mb-2" src={"/img/pajp_prisma.png"} alt="Logga in" width="100" ></img>
                    </Col>
                    <Col className="mt-4 mb-3 text-center col">
                        <h2>Logga in</h2>
                    </Col>
                    <Col>
                        <p
                            style={{ 'color': 'red' }}                      
                        >{errMess ? 'Felaktig signatur eller pinkod' : ''}</p>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Signatur</Label>
                            <Input
                                name="email"
                                id="exampleEmail"
                                placeholder="ANLAR"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="examplePassword">Pinkod</Label>
                            <Input
                                type="password"
                                name="password"
                                id="examplePassword"
                                placeholder="*****"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Button className="w-100 btn btn-lg btn-dark" disabled={!validateForm()}>Logga in</Button>
                        </FormGroup>
                    </Col>
                </Form>
            </div>
        </div>
    );
}