import React, { useState, useEffect} from "react";
import { Route, Redirect } from 'react-router';


export function PrivateRoute({ comp: Component, authed, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
        />


    )
}