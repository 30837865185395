
export const FetchWrapper = {
    get,
    post,
    put,
    delete: _delete
};

function get(url) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${window.localStorage.getItem("token")}` }

    };
    return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${window.localStorage.getItem("token")}` },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${window.localStorage.getItem("token")}` },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${window.localStorage.getItem("token")}` }
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

function handleResponse(response) {
    return response.text().then(text => {
        
        if (response.status == 401) {
            window.location.href = '/login';
            return Promise.reject("Unauthorized");
        }
        const data = {};
        data.json = text && JSON.parse(text);
        data.headers = response.headers;

        if (!response.ok) {
            const error = response;//(data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}