import React, { Component, useEffect, useState } from 'react';
import { Container, Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import { NavMenu } from './NavMenu';



export const Layout = (props) => {

    const [user, setUser] = useState([]);

    useEffect(() => {

        if (localStorage.getItem("user")) {
            setUser(JSON.parse(localStorage.getItem("user")));
        }
    }, []);


    return (
        <div>
            <Navbar color="light" fixed="top">
                <Nav className="mr-auto" navbar>
                    <NavItem>
                        <NavLink className="nav-link-top" href="#"><img src={"/img/pajp-25x25.png"} className="mr-2"></img> {user.anstNr} - {user.name}</NavLink>
                    </NavItem>
                </Nav>
            </Navbar>
            <Container style={{minHeight: "100vh", position:"relative", paddingTop: 100, marginBottom:100}}>
                {props.children}
            </Container>
            <NavMenu />
        </div>
    );

}
