import React, { Component, useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHome, faSignOutAlt, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { useHistory, NavLink } from 'react-router-dom';

export const NavMenu = (props) => {
    const displayName = NavMenu.name;
    const history = useHistory();


    const tabs = [
    //    {
    //    route: "/",
    //    icon: faHome,
    //    label: "Home"
        //}, 
    {
        route: "/stamp",
        icon: faStopwatch,
        label: "Stämpla"
    }, {
        route: "/login",
        icon: faSignOutAlt,
        label: "Logga ut"
    }]


    const [collapsed, setCollapsed] = useState(true);


    const toggleNavbar = () => {

    }

    const Logout = () => {
        localStorage.removeItem("token");
        history.push("/login");
    }

    return (
        <div>

            {/* Bottom Tab Navigator*/}
            <nav className="navbar fixed-bottom navbar-light bg-light bottom-tab-nav">
                <Nav className="w-100">
                    <div className=" d-flex flex-row justify-content-around w-100">
                        {
                            tabs.map((tab, index) => (
                                <NavItem key={index}>
                                    <NavLink to={tab.route} className="nav-link bottom-nav-link" exact={true} activeClassName="active">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <FontAwesomeIcon size="lg" icon={tab.icon} />
                                            <div className="bottom-tab-label mt-2">{tab.label}</div>
                                        </div>
                                    </NavLink>
                                </NavItem>
                            ))
                        }
                    </div>
                </Nav>
            </nav>
        </div>
    );

}
