import React, { Component, useState, useEffect, Fragment } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Stamp } from './components/Stamp';
import { useHistory } from 'react-router-dom';
import 'bootswatch/dist/yeti/bootstrap.min.css';
import './custom.css'

import { Login } from './components/Authentication/Login';
import { PrivateRoute } from './components/Authentication/PrivateRoute';
export default function App() {
    const displayName = App.name;
    const history = useHistory();
    const [logged, setLogged] = useState(false);


    useEffect(() => {
        if (window.localStorage.getItem("token")) {
        } else {
            history.push("/login");
        }

    }, []);

    return (

        <Fragment>
            <PrivateRoute authed={logged} exact path='/'>
                <Layout>
                    <Stamp />
                </Layout>
            </PrivateRoute>
            <PrivateRoute authed={logged} exact path='/stamp'>
                <Layout>
                    <Stamp />
                </Layout>
            </PrivateRoute>
            <Route path='/login'>
                <Login />
            </Route>
        </Fragment>

    );



}
