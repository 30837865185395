import React, { useState, useEffect, useRef, Fragment } from 'react';



import moment from 'moment'
export const Timer = (props) => {

    const [serverTime, setServerTime] = useState(props.serverTime);
    const [timeStamp, settimeStamp] = useState(props.timeStamp);
    const [seconds, setSeconds] = useState(1);

    useInterval(() => {
        // Your custom logic here
        setSeconds(seconds + 1);
    }, 1000);

    return (
        <span>{moment.utc(moment(serverTime).add(seconds, 'seconds').diff(moment(timeStamp))).format("HH:mm:ss")}</span>
        )

}

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
